﻿// _footer.scss
//@import "../base/colors";
//@import "../base/typography";
@import "../helpers/mixins";

#fwc-footer {
    background-color: $flmtph-mediumbluegray;
    color: white;
    margin-top: 60px;
    padding: 19px 0;
    border-top: 6px solid $flmtph-darkbluegray;
    border-bottom: 6px solid $flmtph-darkbluegray;
    font-family: $base-font-family;

    a {
        color: white;
        font-weight: $bold;

        &:hover, &:focus {
            text-decoration: underline;
            color: white;
        }
    }

    .footer-boxed {
        background-color: $flmtph-darkbluegray;
        padding: 28px;

        @media #{$below768} {
            margin-bottom: 30px;
        }

        .boxed-title {
            display: block;

            &.allsocial {
                font-weight: $regular;
            }

            &.social {
                margin: 30px 0 10px;
            }

            &.subscribe {
                margin-top: 15px;
                font-weight: $regular;

                .svg-inline--fa {
                    margin-left: 10px;
                }
            }
        }
    }

    h3.footer-link-section-title {
        font-size: 21px;
        font-weight: $regular;
        color: $fwc-light-tan;
        margin: 0 0 15px;
        padding-bottom: 8px;
        border-bottom: 1px solid $fwc-gray-medium;
    }

    ul.footer-links {
        margin: 0 0 30px 0;
        padding-left: 0;

        li {
            padding: 2px;
            list-style-type: none;

            a {
                font-size: 16px;
                font-weight: $regular;
            }
        }
    }

    .copyright {
        font-size: 14px;
        text-align: center;
    }

    .footer-social {
        @include flex;
        flex-wrap: wrap;

        a.social-link {
            position: relative;
            display: block;
            width: 38px;
            height: 38px;
            margin: 5px;
            color: white;
            @include transition;

            &:hover {
                background-color: $fwc-gray-dark !important;
            }

            .svg-inline--fa {
                margin: auto;
                left: 0;
                right: 0;
                @include vertical-align(absolute);
            }

            &.fb {
                background-color: $fb-color;
            }

            &.gp {
                background-color: $googleplus-color;
            }

            &.insta {
                background-color: $insta-color;
            }

            &.pin {
                background-color: $pin-color;
            }

            &.sc {
                background-color: $snapchat-color;
            }

            &.tw {
                background-color: $twitter-color;
            }

            &.yt {
                background-color: $yt-color;
            }

            &.fr {
                background-color: $flickr-color;
            }

            &.vim {
                background-color: $vim-color;
            }

            &.li {
                background-color: $linkedin-color;
            }

            &.ps {
                background-color: $periscope-color;
            }
        }
    }

    .regulatory-statement {
        p {
            text-align: center;
            margin-bottom: 0px;
            padding: 0 10px;

            @media #{$between768and992} {
                padding: 0 125px;
            }
        }
    }
}

#google_translate_element {
    position:relative;
    .goog-te-gadget-simple {
        width:100%;
        height:35px;
        border:0;
        padding-top:5px;
        @include standard-box-shadow;
        img.goog-te-gadget-icon {
            position:absolute;
            left:10px;
            top:8px;
        }
        a.goog-te-menu-value {
            font-family: $base-font-family;
            font-weight: $semi-bold;
            font-size:15px;
            display:block;
            box-sizing: border-box;
            padding-left: 48px;
            margin: 2px 0;
            width:100%;
            color:$fwc-gray-dark;
            span[aria-hidden="true"] {
                float:right;
                margin-right:20px;
            }
            span[style^="border-left"] {
                position:absolute;
                right:50px;
            }
        }
    }
}
.footer-logo {
    width: 185px;
    height: 165px;
    display: block;
    position: relative;
    @include vertical-align;

    @media #{$below768} {
        width: 94px;
    }

    img {
        max-width: 200px;
    }
      
}
.left {
    float: left;
    display: block;
    margin-right: 10px;
    margin-left: 10px;
}
.center {
    float: left;
    display: block;   
    margin-right: 10px;
    margin-left: 10px;
    
}
.right {
    float: left;
    display: block;
    margin-right: 10px;
    margin-left: 10px;
}


