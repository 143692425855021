﻿/* _header.scss -- */
//@import "../base/colors";
//@import "../base/typography";
@import "../helpers/mixins";

#fwc-header {
    background-color: $fwc-warm-white;
    height: 175px;
    border-top: 1px solid $fwc-header-top-border;
    /*border-bottom:2px solid #c5bbb0;*/
    .container {
        position: relative;
        height: 100%;
    }

    .site-logo {
        width: 250px;
        height: 135px;
        display: block;
        position: relative;
        @include vertical-align;

        @media #{$below576} {
            width: 94px;
        }

        img {
            max-width: 300px;
        }

        .logo-title {
            display: block;
            color: $fwc-logo-title;
            font-family: $logo-title-font-family;
            font-weight: $bold;
            position: absolute;
            left: 310px;
            bottom: 28px;
            font-size: 22px;
            line-height: 1.2;

            span {
                display: block;
                white-space: nowrap;
            }

            @media #{$below400} {
                display: none;
            }
        }
    }

    .siteSearch {
        width: 292px;
        height: 44px;
        right: 15px;
        position: absolute;
        top: 35%;

        @media #{$below992} {
            top: auto;
            bottom: 20px;
        }

        @media #{$below480} {
            width: 250px;
        }

        @media #{$below400} {
            width: 170px;
        }

        input#search_text {
            width: 100%;
            height: 44px;
            border: 2px solid $fwc-red;
            border-bottom: 2px solid $fwc-red-shade;
            padding-left: 20px;
            font-size: 18px;
            color: $fwc-gray-light;
            @include standard-box-shadow;

            @media #{$below400} {
                font-size: 13px;
                padding-left: 5px;
            }
        }

        input.site-search-input {
            border: 0;
            position: absolute;
            right: 0;
            top: 0;
            height: 44px;
            width: 57px;
            background-color: $fwc-red;
            background-image: url('/images/search-icon.png');

            &:hover {
                cursor: pointer;
            }
        }
    }

    /*.hamburger {
        display: none;
        position: absolute;
        right: 15px;
        bottom: 72px;
        padding: 0;

        @media #{$below992} {
            display: block;
        }

        .hamburger-box {
            width: 57px;
            height: 42px;
        }

        .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
            width: 100%;
            height: 10px;
            background-color: $fwc-gray-medium;
            border-radius: 0;
            @include standard-box-shadow;
        }

        &.hamburger--slider:not(.is-active) .hamburger-inner:before {
            top: 16px;
        }

        &.hamburger--slider:not(.is-active) .hamburger-inner:after {
            top: 32px;
        }

        &.hamburger--slider.is-active {
            .hamburger-inner {
                top: 11px;
            }
        }
    }

    .mobile-menu-text {
        display: none;

        @media #{$below992} {
            display: block;
            position: absolute;
            text-transform: uppercase;
            font-size: 21px;
            font-weight: $bold;
            right: 85px;
            bottom: 85px;
        }
    }
*/

}