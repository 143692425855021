﻿// _forms.scss
//@import "../base/colors";
//@import "../base/typography";
@import "../helpers/mixins";

.fwc-form {
    input[type=text], input[type=email], input[type=file], textarea {
        background-color:white;
        border-radius:0;
        width: 100%;
        border: 0;
        padding: 8px 20px;
        font-size: 18px;
        color: $fwc-gray-light;
        border: 1px solid $fwc-teal;
        @include standard-box-shadow;
    }
    textarea {
        min-height:240px;
        border:1px solid $fwc-teal;
    }
    h4 {
        margin-bottom:15px;
    }
    .umbraco-forms-fieldset {
        margin-bottom:30px;
    }
    .fwc-form-control {

    }
}

.drop-label {
    position: relative;
    vertical-align: middle;
    display: block;
    .svg-inline--fa {
        font-size: 1.2em;
        right: 12px;
        top: 12px;
        position: absolute;
        pointer-events: none;
        display: block;
        color:$fwc-teal;
    }
    select.drop-select {
        background-color: white;
        border: 1px solid $fwc-teal;
        display: inline-block;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        vertical-align: middle;
        font-weight: $regular;
        font-size:17px;
        padding: 10px 0px 10px 10px;
        width: 100%;
        @include standard-box-shadow;
        &::-ms-expand {
            display: none;
        }
    }
    &.disabled {
        opacity: .4;
    }
}