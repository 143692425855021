﻿// _top-navigation.scss
//@import "../base/colors";
//@import "../base/typography";
//@import "../helpers/mixins";

/*.top-nav-wrap {
    background-color: $fwc-gray-medium;
    height: 46px;
    @media #{$below992} {
        display:none;
    }
}

#fwc-mobile-menu:not( .mm-menu ) {
    display: none;
}*/

/*#fwc-nav {
    font-family: $base-font-family;
    height:45px;
    @include link-icons;
    ul.top-level {
        margin:0;
        @include flex;
        justify-content: space-between;
        align-items: stretch;
        margin:0;
        height:100%;
        padding-left:0;
        li {
            margin:0;
            list-style-type:none;
        }
        & > li.dropdown {
            flex-grow: 1;
            border-right:1px solid $fwc-top-nav-border;
            padding:0;
            position: static;
            font-size: calc(10.8px + 4 * ((100vw - 320px) / 800));
            @include transition;
            &:hover a.nav-label-wrap, &.show a.nav-label-wrap {
                cursor:pointer;
                background-color:$fwc-teal;
            }
            &:first-child {
                border-left:1px solid $fwc-top-nav-border;
            }
            @media #{$above1200} {
                font-size:18px;
            }
            span.nav-label {
	            float:left;
            }
            a.nav-label-wrap {
                box-sizing:border-box;
                white-space:nowrap;
                display: block;
                color: white;
                border:0;
                background-color:transparent;
                width: 100%;
                height:100%;
                padding: 7px 32px 0 15px;
                @include vertical-align;
                .svg-inline--fa {
                    font-size:24px;
                    width:24px;
                    position:absolute;
                    right:5px;
                    @media #{$above1200} {
                        margin:3px 0 0;
                    }
                }
                &:hover {
                    cursor:pointer;
                    background-color:$fwc-teal;
                }
            }
        }
    }
    .dropdown-toggle:after {
        display:none;
    }
    .dropdown-menu {
        width:100%;
        border-radius:0;
        border:0;
        color:inherit;
        margin:0;
        padding:0 0 42px;
        border-top:6px solid $fwc-teal;
        transform: translate3d(0px, 45px, 0px)!important;
        @include standard-box-shadow;
        &:before {
            display:block;
            position:absolute;
            top:0;
            left:0;
            content:"";
            height:67px;
            width:100%;
            background-color:#e7f1f2;
            z-index:0;
        }
    }
    h2.drop-column-title {
        height: 67px;
        padding-top: 31px;
        font-size:22px;
        margin:0;
        border-bottom:1px solid $fwc-gray-light;
    }
    ul.drop-column-list {
        margin:15px 0 0;
        padding-left:0;
        overflow:visible;
        > li {
            padding:0 0 10px 0;
            a {
                font-size:$base-font-size;
                font-weight:$bold;
                &:hover, &:focus {
                    color:$fwc-red;
                    text-decoration:underline;
                }
            }
            ul {
                margin:10px 0 0 15px;
                padding-left:0;
                overflow:visible;
                li {
                    padding:0 0 8px 0;
                    a {
                        font-size:18px;
                        font-weight:$medium;
                    }
                }
            }
        }
        li.drop-column-see-all {
            border-bottom:1px solid $fwc-red;
            margin-left:-15px;
            margin-bottom:20px;
            padding: 0 0 3px;
            a {
                color:white;
                display:inline;
                padding:6px 15px;
                background-color:$fwc-red;
                position:relative;
                box-sizing:border-box;
                .svg-inline--fa {
                   margin:0 0 0 10px;
                   padding-top:4px;
                   font-size:24px;
                }
            }
        } 
    }
}*/


// Added Styling from W3Schools (JK 9/19/2019)


.topnav {
    overflow: hidden;
    background-color: #839eb8; //#e2b460; (10/8/2020)
}

.topnav a {
    float: left;
    display: block;
    color: #214263;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.topnav a:hover {
    background-color: #2c5986; //#eedca8; (10/08/2020)
    color: #ffffff; //#5e3a34;
}

.topnav a.active {
    background-color: #2c5986; //#df9941; (10/8/2020)
    color: #ffffff; //#5e3a34;
}

.topnav .icon {
    display: none;
}

@media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {
        display: none;
    }

    .topnav a.icon {
        float: right;
        display: block;
        color: #5e3a34;
    }
}

@media screen and (max-width: 600px) {
    .topnav.responsive {
        position: relative;
    }

    .topnav.responsive .icon {
        position: absolute;
        right: 0;
        top: 0;
    }

    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}