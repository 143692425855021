﻿// _page-title.scss
//@import "../base/colors";
//@import "../base/typography";
@import "../helpers/mixins";

h1.page-title {
    font-size:30px;
    margin-bottom:46px;
    border-bottom:1px solid $fwc-medium-tan;
    border-top:1px solid $fwc-medium-tan;
    padding:16px 0;
    @include title-swoosh();

    @media #{$below768} {
        background-image:none;
        height:auto;
    }
}
ul.fwc-breadcrumb {
    margin:30px 0 1rem;
    font-size:14px;
    font-family: $base-font-family;
    font-weight: $bold;
    padding-left:0;
    li {
        display:inline-block;
        padding:0;
        list-style-type:none;
        .svg-inline--fa {
            font-size:16px;
            margin:3px 10px 0;
        }
    }
}
