﻿// _theme_main.scss

// resets
@import "../base/reset";

// libraries
@import "bootstrap";
@import "../vendor/jquery.mmenu.all";
@import "../vendor/animate";
@import "../vendor/hamburgers"; 

// Sass utilities
@import "../helpers/mixins";
@import "../helpers/mediaqueries";

// Base stuff
@import "../base/typography";
@import "../base/colors";
@import "../base/fonts";
@import "../base/variables.scss";

// Layout related stylesheets
@import "../layout/top-navigation";
// @import "../layout/mobile-menu"; Not using in this instance JK 9/19/2019
@import "../layout/header";
@import "../layout/page-title";
@import "../layout/forms";
@import "../layout/footer";

// Components and modules
@import "../components/buttons";


// Styles

body {
    font-family: $base-font-family;
    color: $fwc-not-quite-black;
    font-weight: $base-font-weight;
    font-size: $base-font-size;
    line-height: $base-line-height;
    background-color:$fwc-warm-white;
}

.wrapper {
    max-width:1920px;
    margin:auto;
    overflow:hidden;
    @media #{$above1920} {
        position:relative;
        &:before, &:after {
            position:absolute;
            content:"";
            width:60px;
            height:100%;
            top:0;
            background-repeat:repeat-y;
            background-size:100%;
            z-index:50;
        }
        &:before {
            background-image:url();
            background-position:top left;
            left:0;
        }
        &:after {
            background-image:url();
            background-position:top right;
            right:0;
        }
    }
}
img {
    max-width: 100%;
}
a {
    color:$fwc-blue;
    font-weight:500;
    @include transition;
    &:hover {
        text-decoration:none;
    }
}
a.read-more {
    font-weight:700;
    .svg-inline--fa {
        margin:3px 0 0 5px;
        @include transition;
    }
    &:hover, &:focus {
        .svg-inline--fa {
            margin:3px 0 0 10px;
        }
    }
}
a,input,button,select,textarea {
    &:focus {
        outline: none;
        box-shadow:0px 0px 3px 3px #4C9AFF!important;
    }
}
//Separated caption so that no bgcolor appears on RTEs 5/10/2021 JK
.caption {
    @include caption;
    color: white;
    background-color: rgba(57,58,59,.87)
}
.caption-rte {
    @include caption;
    font-size: 14px;
    color: black;
    max-width: 360px;
}
.clear {
    clear:both;
}
.container {
    position:relative;
    &.static {
        position:static;
    }
    @media #{$above1200} {
        max-width:1170px;
    }
    @media #{$below992} {
        width:100%;
        max-width:100%;
    }
    .container {
        padding-right:0;
        padding-left:0;
    }
}
ul.news-list {
    margin:30px 0 0;
    padding:0;
    overflow:visible;
    li {
        padding:0 0 20px;
        margin-bottom:20px;
        border-bottom:1px solid $fwc-medium-tan;
        list-style-type:none;
    }
    h3 {
        font-size:17px;
        margin-bottom:10px;
    }
    &.home {
        font-size:.85em;
    }
    p {
        line-height:1.2em;
        margin: .8rem 0;
    }
    .news-date {
        color:#5b5b5b;
        font-weight:700;
    }
}
a.backToTop {
    color:white;
    border-radius:100px;
    background-color:$fwc-gray-dark;
    height:50px;
    width:50px;
    position:fixed;
    right:30px;
    bottom:30px;
    text-decoration:none;
    z-index:10;
    opacity:0;
    visibility:hidden;
    @include transition(.5s);
    &.btt-in {
        visibility:visible;
        opacity:.5;
    }
    .svg-inline--fa {
        display:block;
        text-align:center;
        font-size:40px;
        width:100%;
        margin-top: -1px;
        @include vertical-align;
    }
    @media #{$below768} {
        display:none;
    }
}
.loading-events {
    text-align:center;
    color:$fwc-brown;
}
ul.pagination {
    margin:15px 0;
    padding:0;
    li.page-item {
        list-style-type:none;
        padding:0;
        a.page-link {
            color:$fwc-blue;
            min-height:45px;
            min-width:35px;
        }
        &.active {
            a.page-link {
                color:white;
                background-color:$fwc-blue;
                border-color:$fwc-blue;
            }
        }
    }
}

.with-margin {
    margin: 30px 0;
}

.margin-top {
    margin-top: 30px;
}

.margin-bottom {
    margin-bottom: 30px;
}

div.brown {
    @include colored-row-brown;
}

div.green {
    @include colored-row-green;
}

div.teal {
    @include colored-row-teal;
}

div.white {
    @include colored-row-white;
} 

[v-cloak] {
    display: none;
}

.site-body figure {
    position:relative;
    display:table;
    figcaption img {
        display:block;
        width:100%;
    }
    figcaption {
        @include caption();
        position:relative;
        display:table-caption;
        caption-side:bottom;
    }
}
@-moz-document url-prefix() { 
  figure figcaption {
     display:block!important;
  }
}