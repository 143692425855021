﻿// _buttons.scss
@import "../base/colors";
@import "../base/typography";
@import "../helpers/mixins";

/*Moved fwc-button mixin to the mixin file | Andrew 2/4/20*/

.base-fwc-button {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size:1.2rem;
    text-align:center;
    text-transform:uppercase;
    color:white;
    display:inline-block;
    padding:12px 40px;
    position:relative;
    max-width:100%;
    box-sizing:border-box;
    border:0;
    text-shadow:1px 1px 1px rgba(0,0,0,.2);
    @include transition;
    &:after, &:before {
        position:absolute;
        content:"";
        display:block;
        right:4px;
        left:4px;
        width: 100% -8px;
	    height: 2px;
    }
    &:after {
        bottom:3px;
        background-color:rgba(0,0,0,.2);
    }
    &:before {
        top:3px;
        background-color:rgba(255,255,255,.2);
    }
    &:hover, &:focus, &:active {
        cursor:pointer;
        &:after, &:before {
            display:none;
        }
    }
    @include link-background-image($background-color-default: false, $selector: null, $add-no-repeat: true, $padding-left: null, $background-position: 20px);
}

.fwc-button {
    &.blue {
        @include fwc-button($fwc-blue, $fwc-blue-shade);
    }
    &.teal {
        @include fwc-button($fwc-teal, $fwc-teal-shade);
    }
    &.green {
        @include fwc-button($fwc-green, $fwc-green-shade);
    }
    &.red {
        @include fwc-button($flmtph-darkgold, $flmtph-lightgold);
    }
    &.brown {
        @include fwc-button($fwc-brown, $fwc-brown-shade);
    }
}
